import _ from 'lodash';
import qs from 'qs';
import React, { Component, SyntheticEvent } from 'react';
import { DELETE_STRATEGY, DOWNLOAD_REPORT, EDIT_STRATEGY, MARK_AS_TEST, STRATEGY_FLIGHT_RUNS, STRATEGY_TYPE, UNMARK_AS_TEST, VIEW_ANALYTICS } from 'constantsBase';
import { Modal, MultiSelect, Popup, Menu } from 'buildingBlocks';
import { pluralizer } from 'utils/formattingUtils';
import { ArrayDataProvider } from 'buildingBlocks/TwoListMultiSelect/DataProvider';
import { WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import { Permission, checkPermissions } from 'utils/featureFlags';
import { Member, StrategyType, User, Flight } from 'utils/types';
import { DataProviderInterface } from 'buildingBlocks/TwoListMultiSelect/TwoListMultiSelect';
import { isAdminOrQAGlobal } from 'utils/functionHelpers';
import HeliosReportModalFooter from './HeliosReportModalFooter';

const HELIOS_STRATEGY_TYPE: Array<number> = [STRATEGY_TYPE.helios.id, STRATEGY_TYPE.heliosSegmentRecency.id];
const heliosStrategyType = (strategyTypeId: number) => HELIOS_STRATEGY_TYPE.includes(strategyTypeId);

const strategyOptions = {
  color: 'gray',
  fontWeight: 'normal',
};

type Props = {
  /** Trigger element which, when clicked, will display the strategy links. */
  trigger: JSX.Element,
  /** When true, disable this dropdown. */
  disabled: boolean,
  /** Whether to show a link taking user to the analytics page for the strategy. */
  showAnalyticsLink?: boolean,
  /** The member to which this strategy belongs, used for permissioning. */
  member?: Member,
  /**
   * The flights to which this strategy is attached. For Helios strategies, this
   * is used to populate a modal dialog from which users may download reports.
   */
  flights?: Array<Flight>,
  /** This strategy id. */
  id: number | string,
  /** This strategy type. */
  strategyType?: StrategyType,
  /** Function called when user deletes this strategy. */
  onDeleteClick?: (event: SyntheticEvent, data: unknown) => void,
  /** Whether the 'Delete Strategy' button should be hidden. */
  hideDelete?: boolean,
  /** Whether the 'Download Strategy' button should be hidden. */
  hideDownload?: boolean,
  /** Function called when user triggers a helios report download. */
  heliosDownloadReport?: Function,
  /** Whether helios report data is currently downloading. */
  downloadingReport?: boolean,
  /**
   * Total number of downloaded reports for this strategy. Used to populate a
   * progress bar in a modal dialog from which users may download reports.
   * Relevant only to helios strategies.
   */
  downloadedReport?: number,
  user: User,
  numFlights?: number,
  onTestClick?: Function,
  clientTest?: boolean
};

type State = {
  open: boolean
  dataProvider: DataProviderInterface
  flights: Array<Object>
};

/**
 * Renders a dropdown of options relevant to a particular strategy. These
 * may include Delete, Manage Flights, View Analytics, and Edit Strategy. The
 * particular options displayed depend on the strategy type and the user's
 * permissions.
 */
class EditStrategyStep extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      dataProvider: new ArrayDataProvider(
        _.values(props.flights),
        // @ts-ignore hard
        (search) => (item) => _.includes(item.name.toLowerCase(), search.toLowerCase()),
      ),
      flights: _.values(props.flights),
    };
  }

  render() {
    const {
      trigger, disabled, showAnalyticsLink, onDeleteClick, hideDelete, hideDownload,
      id, member, strategyType, heliosDownloadReport, downloadingReport, downloadedReport, user, onTestClick, clientTest,
    } = this.props;
    const items = [];
    const canEdit = checkPermissions(user, Permission.accessStrategyWizard, member);
    const deleteStrategy = checkPermissions(user, Permission.deleteStrategy, member);
    const wizardStepBasedOnStratType = WIZARD_STEPS[WizardSteps.attachFlightsStep].id;
    const isAdmin = isAdminOrQAGlobal(user);
    const clientTestOption = clientTest ? UNMARK_AS_TEST : MARK_AS_TEST;

    if (canEdit) {
      items.push(
        <Menu.Item
          href={`/strategies/wizard/${id}/${wizardStepBasedOnStratType}`}
          key={EDIT_STRATEGY}
          style={strategyOptions}
          name={EDIT_STRATEGY}
        />,
      );
    }
    if (showAnalyticsLink) {
      items.push(
        <Menu.Item
          href={`/strategies/${id}`}
          key={VIEW_ANALYTICS}
          style={strategyOptions}
          name={VIEW_ANALYTICS}
        />,
      );
    }
    if (deleteStrategy && !hideDelete) {
      items.push(
        <Menu.Item
          key={DELETE_STRATEGY}
          style={strategyOptions}
          name={DELETE_STRATEGY}
          onClick={onDeleteClick}
        />,
      );
    }
    if (heliosStrategyType(_.get(strategyType, 'id')) && !hideDownload) {
      items.push(
        <Menu.Item
          key={DOWNLOAD_REPORT}
          className="ess-dropdown"
          style={strategyOptions}
          name={DOWNLOAD_REPORT}
          onClick={() => this.setState({ open: true })}
        />,
      );
    }
    if (isAdmin) {
      const queryStringVals = { strategy: [id], limit: 25, skip: 0 };
      const stringified = qs.stringify(queryStringVals);
      items.push(
        <Menu.Item
          href={`/strategyflightruns?${stringified}`}
          key={STRATEGY_FLIGHT_RUNS}
          style={strategyOptions}
          name={STRATEGY_FLIGHT_RUNS}
          target="_blank"
        />,
        <Menu.Item
          key={clientTestOption}
          style={strategyOptions}
          name={clientTestOption}
          onClick={() => onTestClick()}
        />,
      );
    }
    return (
      !_.isEmpty(items) && (
      <>
        <Popup
          style={{ padding: 0 }}
          trigger={<div>{trigger}</div>}
          on="click"
          disabled={disabled}
          position="bottom right"
          flowing
          hideOnScroll
          hoverable
        >
          <Menu secondary vertical>
            {_.map(items, (item) => item)}
          </Menu>
        </Popup>
        <Modal
          size="large"
          open={this.state.open}
          style={{ minHeight: '30em' }}
        >
          <Modal.Content>
            <MultiSelect
              dataProvider={this.state.dataProvider}
              keyFn={(flight: Flight) => flight.id}
              displayFn={(flight: Flight) => flight.name}
              sortFn={(flight: Flight) => flight.name}
              pluralize={pluralizer('Flight', 'Flights')}
              loading={downloadingReport}
              loadingMsg="Download in progress..."
              defaultValue={this.state.flights}
              footer={(
                <HeliosReportModalFooter
                  {...this.props}
                  onCancel={() => this.setState({ open: false })}
                  heliosDownloadReport={heliosDownloadReport}
                  downloadingReport={downloadingReport}
                  downloadedReport={downloadedReport}
                />
              )}
            />
          </Modal.Content>
        </Modal>
      </>
      ));
  }
}

export default EditStrategyStep;
