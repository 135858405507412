import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES } } = COPILOT_COLORS;

export const titleStyle: CSSProperties = {
  fontWeight: 500,
  margin: '32px 0px 0px',
};

export const msgStyle: CSSProperties = {
  color: NEUTRALS.N600_ABBEY,
  fontSize: 18,
  padding: '32px 0px',
  fontWeight: 600,
};

export const unknownErrorMsgStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

export const btnStyle: CSSProperties = {
  borderRadius: '4px',
  fontWeight: 600,
  padding: '8px 16px',
  textTransform: 'capitalize',
  fontSize: 14,
};

export const PAGE_FORBIDDEN_STRATEGY_WIZARD: { [key: string]: CSSProperties } = {
  summaryDiv: {
    marginBottom: COPILOT_LAYOUT.SPACING[16],
  },
  requestAccessP: {
    fontWeight: 400,
  },
  listStyle: {
    margin: '16px 0px',
  },
  dspIcon: {
    marginTop: '-3px',
    verticalAlign: 'middle',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  requestAccesBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    padding: '5px 16px',
    backgroundColor: NEUTRALS.N0_WHITE,
    color: NEUTRALS.N800_MATTER,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    borderRadius: 4,
    marginRight: COPILOT_LAYOUT.SPACING[16],
    textTransform: 'capitalize',
  },
  goBackBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    padding: '5px 22px',
    backgroundColor: BLUES.B500_WAVE,
    color: NEUTRALS.N0_WHITE,
    borderRadius: 4,
    textTransform: 'capitalize',
  },
  unknownErrorContent: {
    color: '#343A3F',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    width: '400px',
  },
  unknownErrorLink: {
    color: '#0014CC',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    cursor: 'pointer',
  },
};

export const centerAuto: CSSProperties = { margin: 'auto', marginTop: 128 };
