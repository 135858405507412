import React, { ReactNode } from 'react';
import { Modal, Button, Header } from 'buildingBlocks';

type ConfirmationModalComponentProps = {
  icon?: string
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onSubmit?: Function
  open: boolean
  modelName: string
  message: string | JSX.Element
  hideDefaultBtns: boolean
  customBtns: ReactNode
  size: 'fullscreen' | 'large' | 'mini' | 'small' | 'tiny'
  submitName?: string
  cancelName?: string
  dimmer?: string
};
export const ConfirmationModalComponent = ({
  onCancel,
  open,
  onSubmit,
  modelName,
  message,
  icon,
  customBtns,
  hideDefaultBtns,
  size,
  submitName,
  cancelName,
  dimmer = 'blurring',
}: ConfirmationModalComponentProps) => (
  <Modal
    open={open}
    size={size}
    dimmer={dimmer}
  >
    <Header icon={icon} content={modelName} />
    <Modal.Content>
      {message}
    </Modal.Content>
    <Modal.Actions>
      { !hideDefaultBtns && (
      <div>
        <Button primary onClick={() => onSubmit()}>
          {submitName}
        </Button>
        <Button secondary onClick={onCancel}>
          {cancelName}
        </Button>
      </div>
      )}
      {customBtns}
    </Modal.Actions>
  </Modal>
);

/* eslint-disable react/default-props-match-prop-types */
ConfirmationModalComponent.defaultProps = {
  hideDefaultBtn: false,
  size: 'small',
  submitName: 'Submit',
  cancelName: 'Cancel',
};
/* eslint-enable */

export default ConfirmationModalComponent;
