/**
*
* FormSectionHeader
*
*/

import React, { ReactNode } from 'react';
import { Header } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip/';

import formSectionHeaderStyle from './styles';

type Props = {
  /** Tooltip group. */
  group?: string,
  /** User-facing title of the FormSectionHeader. */
  title?: string | ReactNode,
  /** Tooltip content. */
  help?: string | ReactNode,
  /** Tooltip name. */
  tooltipName?: string,
  /** Whether to align FormSectionHeader to the left.  */
  leftJustified?: boolean,
  /** Style object to apply. */
  headerStyles?: Object,
  /** Children to render in between the header and the tooltip */
  children?: ReactNode,
  /** whether to style inline */
  shouldStyleHeaderInline?: boolean,
};

const FormSectionHeader = ({
  group, title, help, tooltipName, leftJustified, headerStyles, children, shouldStyleHeaderInline,
}: Props) => {
  let style = { ...formSectionHeaderStyle.header };
  let divStyle: { [key: string]: string } = { display: 'flex', justifyContent: leftJustified ? 'left' : 'space-between' };
  if (leftJustified) {
    style = { ...formSectionHeaderStyle.header, ...formSectionHeaderStyle.leftJustified };
  }
  if (headerStyles) {
    style = { ...style, ...headerStyles };
  }
  if (shouldStyleHeaderInline) {
    style = { ...style, ...formSectionHeaderStyle.inline };
    divStyle = { display: 'inline' };
  }
  return (
    <div style={divStyle}>
      <Header size="small" style={style}>
        {title}
      </Header>
      {children}
      {help
      && (
      <div className="tooltipIcon" style={formSectionHeaderStyle.icon}>
        <Tooltip content={help} group={group} name={tooltipName || title as string} />
      </div>
      )}
    </div>
  );
};

export default FormSectionHeader;
