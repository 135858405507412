import React from 'react';
import { Modal, Icon } from 'buildingBlocks';
import style from '../style';

const ModalHeader = () => (
  <Modal.Header style={style.modalHeader}>
    <div style={{ textAlign: 'center' }}>
      <Icon name="calculator" />
      <h3 style={{ fontWeight: 100, lineHeight: '8px' }}>
        ROAS Calculator
      </h3>
    </div>
  </Modal.Header>
);

export default ModalHeader;
