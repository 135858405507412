import _ from 'lodash';
import React, { Component, SyntheticEvent } from 'react';
import { Modal, Grid, Button, Input } from 'buildingBlocks';
import { GenericFormEventHandler } from 'utils/types';
import ModalHeader from './components/ModalHeader';
import CalcTrigger from './components/CalcTrigger';
import { calculateConvRevGoalValue } from './utils';
import style from './style';

type InputBoxProps = {
  label: string,
  placeholder: string,
  currency?: string,
  onChange: GenericFormEventHandler,
};

const InputBox = ({ label, placeholder, currency, onChange }: InputBoxProps) => (
  <Grid.Row className="calc-inputbox no-spinners" style={style.inputForm}>
    <label style={style.label}>{label}</label>
    <Input
      style={!currency ? { width: '166px' } : {}}
      type="number"
      placeholder={placeholder}
      label={currency ? { content: currency } : undefined}
      labelPosition={currency ? 'right' : undefined}
      onChange={onChange}
    />
  </Grid.Row>
);

type Props = {
  label?: string,
  onChange: Function,
};

type State = {
  modalOpen: boolean,
  mediaCostValue: string,
  revPerConversionValue: string,
  conversionVolumeValue: string,

};

export class ConvRevenueCalculator extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
      mediaCostValue: '',
      revPerConversionValue: '',
      conversionVolumeValue: '',
    };
  }

  closeModal = () => {
    this.setState({ modalOpen: false, mediaCostValue: '', revPerConversionValue: '', conversionVolumeValue: '' });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  setGoalValue = (v: number) => {
    this.props.onChange(v);
    this.closeModal();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  onInputChange = (k: string, v: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [k]: v,
    }));
  };

  isNotValid = (isValidResult: boolean) => _.isEqual(this.state.mediaCostValue, '')
    || _.isEqual(this.state.conversionVolumeValue, '')
    || _.isEqual(this.state.revPerConversionValue, '')
    || !isValidResult;

  render() {
    const { modalOpen, revPerConversionValue, conversionVolumeValue, mediaCostValue } = this.state;
    const estimatedConvRevGoalValue = calculateConvRevGoalValue(revPerConversionValue, conversionVolumeValue, mediaCostValue);
    const isValidCalculation = _.isFinite(estimatedConvRevGoalValue) && !_.isNaN(estimatedConvRevGoalValue);
    return (
      <Modal
        open={modalOpen}
        size="tiny"
        trigger={(
          <CalcTrigger
            handleModal={() => {
              this.openModal();
            }}
          />
        )}
      >
        <ModalHeader />
        <Modal.Content style={{ position: 'relative', height: '70%' }}>
          <Grid style={{ minHeight: '14px' }} padded>
            <Grid.Column>
              <p style={{ fontStyle: 'italic' }}>What is ROAS?</p>
              <p>ROAS is the sum of revenue tracked in a conversion pixel,
                divided by the sum of media cost for a given flight.
              </p>
              <p>It can also be referred to as Return on Investment (ROI).</p>
            </Grid.Column>
          </Grid>
          <Grid padded>
            <Grid.Column>
              <strong>Estimate ROAS Goal Value:</strong>
            </Grid.Column>
          </Grid>
          <Grid padded textAlign="right">
            <Grid.Column>
              <InputBox
                label="My estimated media cost is"
                placeholder="e.g. 10,000"
                currency={this.props.label}
                onChange={() => {
                  this.setState({ mediaCostValue: (event.target as HTMLInputElement).value });
                }}
              />
              <InputBox
                label="My estimated revenue per conversion is"
                placeholder="e.g. 50"
                currency={this.props.label}
                onChange={() => {
                  this.setState({ revPerConversionValue: (event.target as HTMLInputElement).value });
                }}
              />
              <InputBox
                label="My estimated Conversion Volume is"
                placeholder="e.g. 500"
                onChange={() => {
                  this.setState({ conversionVolumeValue: (event.target as HTMLInputElement).value });
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid textAlign="center">
            <Grid.Column style={{ display: 'flex' }}>
              <label>My estimated ROAS Goal Value:</label>
              <span style={style.estValue}>
                {isValidCalculation ? estimatedConvRevGoalValue : 0}
              </span>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button onClick={this.closeModal}>Cancel</Button>
          <Button
            primary
            onClick={(e: SyntheticEvent) => {
              this.setGoalValue(estimatedConvRevGoalValue);
              e.stopPropagation();
            }}
            disabled={this.isNotValid(isValidCalculation)}
          >
            Use this value
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ConvRevenueCalculator;
