import React, { MouseEvent } from 'react';
import { Accordion, Icon } from 'buildingBlocks';
import FormSectionHeader from 'components/FormSectionHeader';
import { blueBold } from './style';

type AccordionTitleProps = {
  show: boolean,
  title: string,
  tooltipName: string | null,
  group: string,
  help: string,
  onClick: (event: MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => void,
};

const AccordionTitleComponent = ({
  show, title, tooltipName, group, help, onClick,
}: AccordionTitleProps) => {
  const titleComponent = (
    <div style={blueBold}>
      <Icon name={show ? 'minus' : 'plus'} size="small" />{title}
    </div>
  );
  return (
    <Accordion.Title onClick={onClick} style={{ padding: '0' }}>
      <FormSectionHeader
        title={titleComponent}
        group={group}
        help={help}
        tooltipName={tooltipName || title}
        headerStyles={{ margin: '0' }}
        leftJustified
      />
    </Accordion.Title>
  );
};

export default AccordionTitleComponent;
