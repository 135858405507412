/**
* Sparkline
*/

import React from 'react';
import HighChart from 'react-highcharts';
import sparkline from './styles';
import blankChartBase from './constants';

type Props = {
  /** Data points for the bar chart */
  data: Array<Array<number>>
  /** Name of the chart. */
  name: string
  /** Type of the underlying HighCharts chart, e.g. 'line' or 'area'. */
  type?: string
};

HighChart.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export function createSparklineConfig(name, listOfValues, type = 'area') {
  return {
    ...blankChartBase,
    chart: {
      ...blankChartBase.chart,
      type,
    },
    series: [{
      name,
      color: 'var(--wpp-dataviz-color-cat-neutral-1)',
      lineWidth: 2,
      lineColor: 'var(--wpp-dataviz-color-cat-dark-1)',
      data: listOfValues,
    }],
  };
}

const Sparkline = (props: Props) => (
  <div id="sparkline-high-chart-container" style={sparkline}>
    <HighChart
      // @ts-ignore props overload issue
      config={createSparklineConfig(props.name, props.data, props.type)}
      isPureConfig
    />
  </div>
);

export default Sparkline;
