import React, { CSSProperties } from 'react';
import _ from 'lodash';
import { DSP } from 'constantsBase';
import dspIconStyles from './style';

type Props = {
  dspId: number
  status?: string
  iconStyles?: CSSProperties
  style?: CSSProperties
};

const DspIcon = ({ dspId, status, style, iconStyles = {} }: Props) => {
  const dspContent = DSP.getById(dspId);
  const dspCode = _.get(dspContent, 'code');
  let dspSpanStyle: CSSProperties = { ...dspIconStyles.base, ...dspIconStyles[dspCode], ...iconStyles };
  if (!_.isEmpty(status)) {
    dspSpanStyle = { ...dspSpanStyle, ...dspIconStyles[status] };
  }

  return (
    <span style={{ ...style, ...dspIconStyles.baseContainer }}>
      <span style={dspSpanStyle}>
        {dspCode}
      </span>
    </span>
  );
};

export default DspIcon;
