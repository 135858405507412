import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Button, Grid } from 'buildingBlocks';
import { DATE_FORMAT_JS_DATE } from 'utils/dateTime';

type Props = {
  /**
   * Function to call when the date range changes. If hasApplyButton is set, it only fires when it is clicked,
   * otherwise it will fire when either field changes
   */
  onDateRangeSubmit: Function,
  /**
   * Whether or not to use a button to submit changes.
   */
  hasApplyButton?: boolean,
  /**
   * Initial date range values. If omitted, will be set to (yesterday start of day) - (today end of day)
   */
  initialStartDate?: Moment,
  initialEndDate?: Moment,
  enableFutureDateSelection?: boolean,
  lookbackDays?: number,
  timeZoneEnabled?: boolean,
};

type State = {
  startDate: Date,
  endDate: Date,
  enableFutureDateSelection: boolean,
  lookbackDays: number,
  timeZoneEnabled: boolean,
};

class DateRangePicker extends Component<Props, State> {
  static getInitialStartDate(startDate?: Moment) {
    if (startDate) {
      return moment(startDate).startOf('day');
    }
    return moment().subtract(1, 'days').startOf('day');
  }

  constructor(props: Props) {
    super(props);
    const startDate = DateRangePicker.getInitialStartDate(this.props.initialStartDate).toDate();
    const endDate = moment(this.props.initialEndDate).endOf('day').toDate();

    this.state = {
      startDate,
      endDate,
      enableFutureDateSelection: this.props.enableFutureDateSelection || false,
      lookbackDays: this.props.lookbackDays || 30,
      timeZoneEnabled: this.props.timeZoneEnabled,
    };
  }

  handleDateChanged = () => {
    if (!this.props.hasApplyButton) {
      if (this.state.startDate && this.state.endDate) {
        this.handleSubmit();
      }
    }
  };

  handleStartDateChange = (startDate: Date) => {
    if (moment(startDate).isAfter(this.state.endDate)) {
      this.setState({ endDate: moment(startDate).endOf('day').toDate() });
    }
    this.setState({ startDate }, () => {
      this.handleDateChanged();
    });
  };

  handleEndDateChange = (endDate: Date) => {
    if (moment(endDate).isBefore(this.state.startDate)) {
      this.setState({ startDate: moment(endDate).startOf('day').toDate() });
    }
    this.setState({ endDate }, () => {
      this.handleDateChanged();
    });
  };

  handleSubmit = () => {
    const { startDate, endDate } = this.state;
    this.props.onDateRangeSubmit(startDate, endDate);
  };

  render() {
    const datePickerProps = {
      class: 'datePicker',
      selected: this.state.startDate,
      onChange: this.handleStartDateChange,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      maxDate: this.state.enableFutureDateSelection ? null : moment(),
      minDate: moment().subtract(this.state.lookbackDays, 'days'),
      selectsStart: true,
      dateFormat: DATE_FORMAT_JS_DATE,
    };

    return (
      <Form>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column width={this.state.timeZoneEnabled ? 6 : 2}>
              <Form.Field>
                <label htmlFor="datePicker">Select Start Date</label>
                <DatePicker {...datePickerProps} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={this.state.timeZoneEnabled ? 6 : 2}>
              <Form.Field>
                <label htmlFor="datePicker">Select End Date</label>
                <DatePicker
                  {...datePickerProps}
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  selectsEnd
                  selectsStart={false}
                />
              </Form.Field>
            </Grid.Column>
            {
              this.props.hasApplyButton
              && (
              <Grid.Column width={2}>
                <Form.Field>
                  <Button onClick={this.handleSubmit} style={{ position: 'absolute', bottom: 0 }}>Apply</Button>
                </Form.Field>
              </Grid.Column>
              )
            }
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default DateRangePicker;
