import React from 'react';
import { Icon } from 'buildingBlocks';
import { GenericMouseEventHandler } from 'utils/types';

type Props = {
  handleModal: GenericMouseEventHandler,
};

const CalcTrigger = ({ handleModal } : Props) => (
  <Icon className="calc-trigger" link name="calculator" onClick={handleModal} />
);

export default CalcTrigger;
