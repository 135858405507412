import { CSSProperties } from 'react';
import {
  COPILOT_COLORS,
  COPILOT_LAYOUT,
  COPILOT_TYPOGRAPHY,
} from 'globalStyles';

export const styles: { [key: string]: CSSProperties } = {
  container: {
    backgroundColor: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N100_CLOUD,
    padding: COPILOT_LAYOUT.SPACING[16],
    position: 'relative',
    border: `1px solid ${COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N200_LINEN}`,
    borderRadius: 4,
    transitionDuration: '500ms',
  },
  flashInfoBanner: {
    // backgroundColor: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
    color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N0_WHITE,
    transitionDuration: '500ms',
    // padding: COPILOT_LAYOUT.SPACING[16],
    // position: 'relative',
    // border: `1px solid ${COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N200_LINEN}`,
    // borderRadius: 4,
  },
  collapseButton: {
    fontSize: 16,
    color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N800_MATTER,
    marginRight: 8,
    cursor: 'pointer',
  },
  headerText: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    fontWeight: 500,
    color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N800_MATTER,
    display: 'inline-block',
  },
  bodyCopy: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY,
    marginTop: 4,
  },
  summaryText: {
    ...COPILOT_TYPOGRAPHY.DESCRIPTION.BOLD,
    fontWeight: 400,
    color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY,
    position: 'absolute',
    right: COPILOT_LAYOUT.SPACING[40],
    top: COPILOT_LAYOUT.SPACING[16],
  },
  infoIcon: {
    position: 'absolute',
    right: COPILOT_LAYOUT.SPACING[16],
    top: COPILOT_LAYOUT.SPACING[16],
  },
};

export default styles;
