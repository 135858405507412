// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationItemSubjectLineClamp::part(typography) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.notificationItemDescLineClamp::part(typography) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.notification-item:hover {
  background-color: var(--wpp-grey-color-200) !important;
}
.notification-item.new {
  background-color: var(--wpp-grey-color-000);
}

.notificationItemSubject.read, .notificationItemDesc.read {
  color: var(--wpp-grey-color-500);
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/style.scss"],"names":[],"mappings":"AAOA;EANE,oBAAA;EACA,4BAAA;EACA,gBAAA;EACA,qBAIoB;AAHtB;;AAMA;EAVE,oBAAA;EACA,4BAAA;EACA,gBAAA;EACA,qBAQoB;AAAtB;;AAIE;EACE,sDAAA;AADJ;AAGE;EACE,2CAAA;AADJ;;AAME;EACE,gCAAA;AAHJ","sourcesContent":["@mixin webkit-box($line-clamp){\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  -webkit-line-clamp: $line-clamp;\n}\n\n.notificationItemSubjectLineClamp::part(typography){\n  @include webkit-box(2);\n}\n\n.notificationItemDescLineClamp::part(typography){\n  @include webkit-box(1);\n}\n\n.notification-item{\n  &:hover{\n    background-color: var(--wpp-grey-color-200) !important;\n  }\n  &.new{\n    background-color: var(--wpp-grey-color-000);\n  }\n}\n\n.notificationItemSubject, .notificationItemDesc {\n  &.read{\n    color: var(--wpp-grey-color-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
