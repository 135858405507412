import React, { CSSProperties, ReactNode } from 'react';
import { Grid, Accordion } from 'buildingBlocks';
import AccordionTitleComponent from './AccordionTitleComponent';
import { shortColumn, shortRow, accordionContent } from './style';

type AccordionProps = {
  /** The title of the Accordion. */
  title: string,
  /** Tooltip name. */
  tooltipName?: string | null,
  /** Tooltip group. */
  displayName: string,
  /** Tooltip content. */
  help?: string,
  /** Child elements for the accordion. */
  children: string | ReactNode,
  /** User-facing description of the accordion. */
  description?: string | ReactNode,
  /** Function invoked when user clicks on the accordion title. */
  onClick: Function,
  /** Style object applied to the accordion's content. */
  contentStyle?: {},
  shouldShowContent: boolean,
  rowStyle?: CSSProperties,
  columnStyle?: CSSProperties
};

const AccordionComponent = (props: AccordionProps) => {
  const {
    title,
    tooltipName,
    displayName,
    help,
    children,
    description,
    onClick,
    contentStyle,
    shouldShowContent,
    rowStyle,
    columnStyle,
  } = props;

  const accodionContentSyle = contentStyle ? { ...accordionContent, ...contentStyle } : accordionContent;

  const titleClick = () => {
    onClick();
  };

  return (
    <Grid.Row style={rowStyle ?? shortRow}>
      <Grid.Column style={columnStyle ?? shortColumn}>
        <Accordion>
          <AccordionTitleComponent
            show={shouldShowContent}
            title={title}
            tooltipName={tooltipName}
            group={displayName}
            help={help}
            onClick={titleClick}
          />
          <Accordion.Content active={shouldShowContent} style={accodionContentSyle}>
            {description
              && (
              <Grid.Row>
                <Grid.Column style={{ lineHeight: '1.4285em', color: 'inherit' }}>
                  {description}
                </Grid.Column>
              </Grid.Row>
              )}
            <Grid.Row>
              <Grid.Column style={{ marginTop: '1em' }}>
                {children}
              </Grid.Column>
            </Grid.Row>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
};

export default AccordionComponent;
