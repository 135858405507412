import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { WppTopbar, WppTypography } from 'buildingBlocks';
import Notification from 'components/Notification';
import Search from 'containers/Search';
import { WHITELIST_IP_PAGE_NAME } from 'containers/WhitelistIP';
import { ELEMENT_ID } from 'cssTagConstants';
import { GlobalState } from 'reducers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { MEMBER, featureEnabled } from 'utils/featureFlags';
import { EnvironmentVariables, NavigationState, User } from 'utils/types';
import { initializeFreshworksWidget, conditionallyShowFreshDeskWidget } from 'utils/widget';
import { store } from 'index';
import { CLEAR } from 'containers/StrategyWizard/constants';
import { isAdminOrQAGlobal } from 'utils/functionHelpers';
import UserOptions from './components/UserOptions';
import { navItemList, NavigationItem, navigationData } from './constants';
import { logoImageStyle, logoStyle, rightNavContainer, wppTopbar } from './style';
import './style.scss';

type NavBarProps = {
  user: User
};

const Nav: React.FC<NavBarProps> = (props: NavBarProps): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [navValue, setNavValue] = useState<string>('/');
  const [navigationItems, setNavigationItems] = useState<Array<NavigationState>>([]);
  const env = useSelector<GlobalState>((state) => state.login.env);

  const getNavItem = (navItem: NavigationItem): NavigationState => ({
    label: navItem.label,
    value: navItem.value,
    path: navItem.path,
  });

  useEffect(() => {
    setNavValue(_.isEqual(location.pathname, '/') ? 'strategies' : location.pathname.slice(1));
  }, [location]);

  const filteredItems = useMemo(() => {
    const userHasPermission = (item: NavigationItem): boolean => _.isEmpty(item.permission)
      || _.some(item.permission, (permission) => featureEnabled(props.user, permission, MEMBER.ANY));
    const updatedNavData: Array<NavigationItem> = navigationData
      .map((navData: NavigationItem) => ({
        ...navData,
        children: navData.children.filter((childNav: NavigationItem) => {
          if (
            userHasPermission(childNav)
            && _.isEqual(navData.value, ELEMENT_ID.navBar.admin)
            && _.isEqual(childNav.label, WHITELIST_IP_PAGE_NAME)
            && !_.isEqual(env, EnvironmentVariables.dev)
          ) {
            return false;
          }
          return userHasPermission(childNav);
        }),
      }))
      .filter(
        (navData: NavigationItem) => !(
          _.isEqual(navData.label, navItemList.admin.name)
            && (_.isEmpty(navData.children) || !isAdminOrQAGlobal(props.user))
        ),
      );
    return updatedNavData;
  }, [env, props.user]);

  const handleTopbarItemChange = (event: CustomEvent) => {
    if (_.isEqual(event.detail.value, 'nav-bar-new-strategy-link')) {
      store.dispatch({ type: CLEAR });
      // prevents state issues if user hits new strategy while on step 1 of strategy wizard in create mode
      if (
        !window.location.search
        && window.location.pathname === '/strategies/wizard/1'
      ) {
        window.location.reload();
      }
    } else if (_.isEqual(event.detail.value, ELEMENT_ID.navBar.helpCenter)) {
      window.open(event.detail.path, '_blank');
      return;
    }
    setNavValue(event.detail.value);
    navigate(event.detail.path);
  };

  useEffect(() => {
    const getNavItems = (navItems: Array<NavigationItem>): void => {
      const items: Array<NavigationState> = navItems.map(
        (navItem: NavigationItem) => ({
          ...getNavItem(navItem),
          children: navItem.children.map((navChildItem: NavigationItem) => ({
            ...getNavItem(navChildItem),
          })),
        }),
      );
      setNavigationItems(items);
    };

    getNavItems(filteredItems);
  }, [filteredItems]);

  useMount(() => {
    initializeFreshworksWidget(props.user.email);
    conditionallyShowFreshDeskWidget();
  });

  return (
    <WppTopbar
      value={navValue}
      navigation={navigationItems}
      onWppChange={handleTopbarItemChange}
      style={wppTopbar}
      className="wppTopbar"
    >
      <Link to="/" slot="app" style={logoStyle} onClick={() => setNavValue('/')}>
        <img alt="Copilot" style={logoImageStyle} src="/img/copilot-icon-new.png" />
        <WppTypography type="m-strong" tag="h3">
          Copilot
        </WppTypography>
      </Link>
      <div slot="right" style={rightNavContainer}>
        <Search />
        <Notification />
        <UserOptions />
      </div>
    </WppTopbar>
  );
};

export default Nav;
