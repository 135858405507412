import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  WppToggle, WppButton, WppInlineMessage, WppSelect, WppListItem, WppModal, WppDivider, WppTypography, WppIconTrash,
} from 'buildingBlocks';
import { Flight, SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';
import { REPORT_TYPES } from 'containers/StrategyAnalytics/components/View/Helios/constants';
import style from './style';
import './style.scss';

const { modalBodySection, selectFlightsTitle, selectedFlightsContainer, selectedFlightsTitle, selectedFlightsItemContainer, modalActions } = style;

type Props = {
  /** Function called when user cancels dialog. */
  onCancel: Function
  /** Function called when user triggers a helios report download. */
  heliosDownloadReport: Function
  /** Whether helios report data is currently downloading. */
  downloadingReport: boolean
  /**
   * Total number of downloaded reports for this strategy. Used to populate a
   * progress bar in a modal dialog from which users may download reports.
   * Relevant only to helios strategies.
   */
  downloadedReport: number
  /** Whether the modal is open */
  isOpen: boolean
  /** Flights data */
  flights: Array<Flight>
};

type State = {
  bundle: boolean
  reportType: string
  selectedFlights: Array<Flight>
};

const HeliosReportModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { isOpen, flights, ...rest } = props;
  const dispatch = useDispatch();

  const [state, setState] = useState<State>({
    bundle: false,
    reportType: REPORT_TYPES.BUCKETED,
    selectedFlights: flights,
  });

  const onReportTypeChangeOption = (reportType: string) : void => {
    setState((prevState: State) => ({ ...prevState, reportType }));
  };

  const onFlightChangeOption = (flightOption: any) : void => {
    setState((prevState: State) => ({ ...prevState, selectedFlights: flightOption }));
  };

  const downloadReport = () : void => {
    if (!_.isEmpty(state.selectedFlights)) {
      const { bundle, selectedFlights, reportType } = state;

      dispatch(rest.heliosDownloadReport({ strategy: rest, flights: selectedFlights, reportType, bundle }));
    }
  };

  const switchBundle = () : void => {
    setState((prevState: State) => ({ ...prevState, bundle: !prevState.bundle }));
  };

  const onRemoveItem = (flightId: number): void => {
    setState((prevState: State) => ({
      ...state,
      selectedFlights: prevState.selectedFlights.filter((flight) => !_.isEqual(flight.id, flightId)),
    }));
  };

  return (
    <WppModal open={isOpen} size="m" className="heliosReportModal">
      <WppTypography slot="header" tag="p" type="xl-heading">Download Report</WppTypography>
      <div slot="body" style={modalBodySection}>
        {
          !_.isEqual(rest.downloadedReport, 0) && (
            <WppInlineMessage
              size="m"
              type="success"
              className="messageContainer"
              message="Report successfully downloaded."
            />
          )
        }
        <WppTypography style={selectFlightsTitle} type="s-strong">Select Flights</WppTypography>
        <WppSelect
          onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => onFlightChangeOption(event.detail.value)}
          id="flights"
          name="flights"
          type="multiple"
          value={state.selectedFlights}
          disabled={rest.downloadingReport}
        >
          {
            _.map(flights, (flight: Flight) => (
              <WppListItem key={flight.id} value={flight}>
                <p slot="label">{_.capitalize(flight.name)}</p>
              </WppListItem>
            ))
          }
        </WppSelect>
        {
          !_.isEmpty(state.selectedFlights) && (
            <div style={selectedFlightsContainer}>
              <WppTypography style={selectedFlightsTitle} type="s-strong">Selected Flights</WppTypography>
              <div style={selectedFlightsItemContainer}>
                {
                  _.map(state.selectedFlights, (flight: Flight, index: number) => (
                    <span key={flight.id}>
                      <WppListItem className="flightItem" disabled={rest.downloadingReport}>
                        <WppTypography slot="label" tag="p" type="s-body">
                          {flight.name}
                        </WppTypography>
                        <WppIconTrash size="m" slot="right" onClick={() => onRemoveItem(flight.id)} />
                      </WppListItem>
                      { !_.isEqual(_.size(state.selectedFlights), index + 1) && <WppDivider />}
                    </span>
                  ))
                }
              </div>
            </div>
          )
        }
        <WppSelect
          onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => onReportTypeChangeOption(event.detail.value)}
          id="reportType"
          name="reportType"
          value={state.reportType}
          disabled={rest.downloadingReport}
        >
          {
            _.map(REPORT_TYPES, (value) => (
              <WppListItem key={value} value={value}>
                <p slot="label">{_.capitalize(value)}</p>
              </WppListItem>
            ))
          }
        </WppSelect>
        <WppToggle
          checked={state.bundle}
          labelConfig={{ text: 'Download as single file' }}
          disabled={rest.downloadingReport}
          onWppChange={() => switchBundle()}
          required
        />
        <WppInlineMessage size="s" message="Reports are limited to 100,000 rows of data" type="information" />
      </div>
      <div slot="actions">
        <WppDivider />
        <div style={modalActions}>
          <WppButton size="s" variant="secondary" disabled={rest.downloadingReport} onClick={() => rest.onCancel()}>Cancel</WppButton>
          <WppButton disabled={_.isEmpty(state.selectedFlights) || rest.downloadingReport} onClick={downloadReport} size="s">
            Download
          </WppButton>
        </div>
      </div>
    </WppModal>
  );
};

export default HeliosReportModal;
