import React, { CSSProperties } from 'react';
import { Container, Image } from 'buildingBlocks';
import { IMG_ICONS_16_BASE_PATH } from 'constantsBase';
import { COPILOT_TYPOGRAPHY } from 'globalStyles';
import errorStyles from './style';

const { BODY } = COPILOT_TYPOGRAPHY;

type ErrorMessageProps = {
  errorText: string
  style?: CSSProperties
};

const ErrorMessage = ({ errorText, style }: ErrorMessageProps) => (
  <Container style={style ? { ...errorStyles.errorMessage, ...style } : errorStyles.errorMessage}>
    <Image alt="error" src={`${IMG_ICONS_16_BASE_PATH}/exclamation-triangle-red.svg`} />
    <p style={BODY.SMALL}>{errorText}</p>
  </Container>
);

export default ErrorMessage;
