import React, { useState } from 'react';
import { Header, Icon, Popup } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import styles from './style';

type Props = {
  headerText: string
  bodyCopy: string
  tooltipContent?: string
  summaryText?: string
  flashInfoBanner?: boolean
  isCrossPlatformOptimization?: boolean
};

const InfoBanner = ({ headerText, bodyCopy, tooltipContent, summaryText, flashInfoBanner, isCrossPlatformOptimization = false }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const flashBannerHelper = (style) => (flashInfoBanner ? { ...style, ...styles.flashInfoBanner } : style);

  return (
    <div style={flashInfoBanner ? { ...styles.container, ...styles.flashInfoBanner, backgroundColor: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE } : styles.container}>
      <Icon
        name={`chevron ${collapsed ? 'right' : 'down'}`}
        style={flashBannerHelper(styles.collapseButton)}
        onClick={() => setCollapsed(!collapsed)}
      />
      <Header
        as="h6"
        style={flashBannerHelper(styles.headerText)}
        content={headerText}
      />
      { summaryText && <p style={flashBannerHelper(styles.summaryText)}>{summaryText}</p> }
      {
        !isCrossPlatformOptimization && (tooltipContent
          ? <Popup content={tooltipContent} trigger={<Icon name="info circle" style={flashBannerHelper(styles.infoIcon)} />} />
          : <Icon name="info circle" style={flashBannerHelper(styles.infoIcon)} />
        )
      }
      {!collapsed && <p style={flashBannerHelper(styles.bodyCopy)}>{bodyCopy}</p>}
    </div>
  );
};

export default InfoBanner;
