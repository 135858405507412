import { CSSProperties } from 'react';

export const dropzoneStyle: { [key: string]: CSSProperties } = {
  container: {
    border: '1px dashed grey',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 4,
    textAlign: 'center',
    transition: 'opacity 0.25s',
    backfaceVisibility: 'hidden',
    background: 'rgba(153,153,153, 0.1)',
  },
  headerText: {
    position: 'relative',
    top: '43%',
    transform: 'translateY(-43%)',
    color: 'black',
    fontWeight: 500,
    fontSize: '18px',
  },
  subtitleText: {
    position: 'relative',
    top: '40%',
    transform: 'translateY(-40%)',
    fontWeight: 100,
    color: '#999',
  },
  browseText: {
    pointerEvents: 'all',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default dropzoneStyle;
