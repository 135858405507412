import { CSSProperties } from 'react';

const style: { [key: string]: CSSProperties } = {
  modalHeader: {
    borderTop: '10px solid #c3e03d',
  },
  label: {
    marginRight: '9px',
  },
  inputForm: {
    marginBottom: '7px',
  },
  estValue: {
    color: '#37b784',
    fontWeight: 500,
    marginLeft: '6px',
  },
};

export default style;
