/**
 *
 * PageTemplate
 *
 */

import React from 'react';
import DocumentTitle from 'react-document-title';
import WppSubNav from 'components/WppSubNav';
import { WppGrid } from 'buildingBlocks';
import {
  wppBodyContainer,
  wppActionContainer,
  wppPageTemplateContainer,
} from './style';

export type PageTemplateProps = {
  /** The name of the current page. */
  name?: string
  /** The title of the current page. */
  title?: string
  /** The contents of the page. */
  children: React.ReactNode
  /** React element representing sub-navigation. */
  subNavElements?: React.ReactNode
  /** React element representing form actions. */
  actionElements?: React.ReactNode
  /** React element representing a custom header. */
  customHeader?: React.ReactNode
};

const WppPageTemplate = (props: PageTemplateProps) => (
  <div id={props.title || props.name} style={wppPageTemplateContainer}>
    {props.title || props.name ? (
      <DocumentTitle title={props.title || props.name} />
    ) : null}
    <WppGrid container>
      <WppSubNav header={props.name} customHeader={props.customHeader}>
        {props.subNavElements}
      </WppSubNav>
      <WppGrid item all={24} style={wppBodyContainer}>
        {props.children}
      </WppGrid>
      <WppGrid item all={24} style={wppActionContainer}>
        {props.actionElements}
      </WppGrid>
    </WppGrid>
  </div>
);

export default WppPageTemplate;
