import _ from 'lodash';
import React, { ReactNode } from 'react';
import { Message } from 'buildingBlocks';

type FlightDetailsPopUpProps = {
  activeStatus?: { isInactive: boolean }
  header: string
  messages: Array<ReactNode | string>
};

const FlightDetailsPopUp = ({
  activeStatus, header, messages,
}: FlightDetailsPopUpProps) => {
  const isInactive = _.get(activeStatus, 'isInactive');
  return (
    <Message
      className="popUpMessage"
    >
      {isInactive ? _.get(activeStatus, 'message') : <Message.Header>{header}</Message.Header>}
      <Message.List>
        {_.map(messages, (msg, idx: number) => (
          <Message.Item key={idx}>
            {msg}
          </Message.Item>
        ))}
      </Message.List>
    </Message>
  );
};

export default FlightDetailsPopUp;
