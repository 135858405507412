import { COPILOT_COLORS } from 'globalStyles';

export const shortColumn = {
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  paddingTop: '0.0rem',
  paddingBottom: '0.0rem',
};

export const shortRow = {
  paddingTop: '0.50rem',
  paddingBottom: '0.50rem',
};

export const accordionContent = {
  paddingTop: '0.0rem',
};

export const blueBold = {
  color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
  fontWeight: 500,
};
