import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/python/python';

export type CustomTextAreaProps = {
  value: string
  onChange: Function
  name: string
  disabled: boolean
  height: number
  mode: 'python' | 'application/json'
};

const CustomTextArea = ({ name, value, onChange, disabled, height, mode, ...rest }: CustomTextAreaProps) => (
  <div
    id={name}
    // @ts-ignore div has name
    name={name}
    style={{ fontSize: '14px', height: `${height}px`, marginBottom: '15px' }}
  >
    <CodeMirror
      onBeforeChange={(_editor, _data, val) => onChange(val)}
      value={value}
      options={{
        mode,
        lineNumbers: true,
        indentWithTabs: true,
        indentUnit: 4,
        readOnly: disabled && 'nocursor',
      }}
      {...rest}
    />
  </div>
);

export default CustomTextArea;
