// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.satelliteButton::part(button) {
  border-radius: 40px;
  height: 32px;
}

.wppTopbar::part(wrapper) {
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
}

.wpp-internal-tooltip::part(tooltip-content) {
  margin-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".satelliteButton::part(button){\n  border-radius: 40px;\n  height: 32px;\n}\n\n.wppTopbar::part(wrapper){\n  height: 56px;\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.wpp-internal-tooltip::part(tooltip-content){\n  margin-left: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
